.navigationTicks {
    display: grid;
    position: fixed;
    left: -100px;
    top: 40%;
    height: 200px;
    animation-name: navigationTicksAnimation;
    animation-delay: 0.75s;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    z-index: 1000;

    .circle {
        background-color: rgba(0, 0, 0, 0.76);
        height: 12px;
        width: 12px;
        display: block;
        border-radius: 42px;
    }
}

@keyframes navigationTicksAnimation {
    0% {
        left: -100px;
    }
    100% {
        left: 2rem;
    }
}


@media only screen and (max-width: 1350px) {
    .navigationTicks {
        display: none;
    }
}
