.socialMedia {
    display: flex;
    justify-content: space-around;
    max-width: 350px;
    justify-self: end;
    position: fixed;
    right: -100px;
    top: 45%;
    z-index: 1001;
    animation-name: socialMediaAnimation;
    animation-delay: 0.75s;
    animation-fill-mode: forwards;
    animation-duration: 1s;

    a {
        margin-left: 2rem;
    }

    img {
        max-width: 36px;
        max-height: 36px;
    }

    &.vertical {
        display: grid;
        grid-row-gap: 10px;
    }

    &.horizontal{
        display: none;
        position: relative;
        top:unset;
        right: unset;
        animation-name: unset;
        a {
            margin-right: 20px;
            margin-left: 0;
            max-width: 32px;
            max-height: 32px;
            
        }
    }
}

@keyframes socialMediaAnimation {
    0% {
        right: -100px;
    }
    100% {
        right: 2rem;
    }
}

@media only screen and (max-width: 1350px) {
    .socialMedia {
        &.vertical {
            display: none;
        }
    }
}


@media only screen and (max-width: 1150px) {
    .socialMedia {
        &.horizontal {
            display: flex;
        }
    }
}

