@import '../../variable.scss';

.topBar {
    display: flex;
    justify-content: space-between;
    height: $s10;
    position: sticky;
    top: 0;
    z-index: 1001;
    padding: $s1 $s8;

    animation-name: topBarAnimation;
    animation-duration: 1s;
}

.topBarSection {
    flex: 1;
    height: 100%;
    display: grid;

    .logo {
        max-height: $s10;
    }

    &.rightSection {
        display: flex;
        justify-content: flex-end;
    }

    &.middleSection {
        display: grid;
        align-content: center;
        height: 100%;
    }

    &.leftSection {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
            display: grid;
            align-content: center;

            &:hover {
                cursor: pointer;
            }

            &:first-child {
                margin-right: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;

                img {
                    height: 36px;
                    width: 36px;
                    margin-right: 10px;
                }
            }

            a {
                text-decoration: none;
                color: black;

                white-space: nowrap;
            }
        }
    }
}

.tableBookButton {
    display: flex;
    min-height: 60px;
    max-width: 175px;
    height: 100%;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
    padding: 0 2rem;
    width: 50%;
    font-size: 20px;
    transition: 0.25s;

    &:hover {
        background-color: black;
        color: rgb(255, 255, 255);
        cursor: pointer;
    }
}

.burgerMenuButton {
    z-index: 1002;

    &:hover {
        cursor: pointer;
    }
}

@keyframes topBarAnimation {
    0% {
        top: -$s10;
        position: relative;
    }

    99% {
        top: -1px;
        position: relative;
    }

    100% {
        top: 0;
        position: sticky;
    }
}

@media only screen and (max-width: 1150px) {

    .topBar {
        padding: $s1 $s4;

        .topBarSection {
            .logo {
                max-height: $s10;
            }

            &.leftSection {
                display: none;

                span {
                    &:first-child {
                        display: none;
                    }

                    a {
                        text-decoration: none;
                        color: black;
                    }
                }
            }

            &.middleSection {
                justify-content: flex-start;
            }
        }
    }


}

@media only screen and (max-width: 720px) {
    .topBarSection {
        .logo {
            max-height: 80px;
        }

        &.leftSection {
            span {
                &:first-child {
                    display: none;
                }

                a {
                    text-decoration: none;
                    color: black;
                }
            }

            .tableBookButton {
                font-size: 18px;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .topBar {
        padding: $s1 $s2;

        .topBarSection {
            .logo {
                max-height: 70px;
            }

            &.leftSection {
                span {
                    &:first-child {
                        display: none;
                    }

                    a {
                        text-decoration: none;
                        color: black;
                    }
                }

                .tableBookButton {
                    font-size: 16px;
                }

                .burgerMenuButton {
                    position: relative;
                    z-index: 1002;
                    right: 10px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}