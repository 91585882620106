.bookATable{
    position: fixed;
    height: 100vh;
    width: 100vw;
    min-width: 300px;
    top:0;
    left:0;
    display: grid;
    justify-content: center;
    align-content: center;
    background-color: rgba(0, 0, 0, 0.829);
    z-index: 1299;


    .selectedSlot{
        background-color: rgba(0, 0, 0, 0.829);
        color: white;
    }
}