@import '../../variable.scss';

.aboutSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100vh;
    grid-column-gap: 20px;
    margin-bottom: 20px;

    .leftSection {
        position: relative;
        min-height: 820px;
        .backgroundBox {
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgb(235, 235, 235);
            height: 100%;
            width: 100%;
            overflow: hidden;

            .food {
                height: 70%;
                position: absolute;
                left: 0;
                bottom: -500px;
                filter: drop-shadow(30px 10px 4px rgba(0, 0, 0, 0.15));
                animation-duration: 2s;
                animation-fill-mode: forwards;
                animation-name: foodAnimation;
                animation-delay: 0.25s;
            }

            .coffee {
                height: 50%;
                position: absolute;
                right: -200px;
                top: -50px;
                filter: drop-shadow(30px 10px 4px rgba(0, 0, 0, 0.15));
                animation-duration: 2s;
                animation-fill-mode: forwards;
                animation-name: coffeeAnimation;
            }
        }
    }

    .rightSection {
        h1 {
            text-align: left;
            font-weight: normal;
            max-width: 600px;
        }
        p {
            text-align: left;
            max-width: 600px;
            font-family: $bodyFont;
        }

        .headerUnderline {
            max-width: 250px;
            margin-bottom: 30px;
        }

        h5{
            text-align: left;
        }

        .featureSection {
            display: grid;
            grid-template-columns: 1fr 1fr;
            max-width: 600px;
            margin-top: 50px;
            grid-column-gap: 10px;

            .feature {
                display: grid;
                align-content: start;
            }
            img {
                height: 100px;
                width: 100px;
                justify-self: left;
            }

        }
    }
}

@keyframes foodAnimation {
    0% {
        bottom: -500px;
        transform: rotateZ(-45deg);
    }
    100% {
        bottom: -100px;
        transform: rotateZ(0);
    }
}

@keyframes coffeeAnimation {
    0% {
        right: -500px;
        transform: rotateZ(145deg);
    }
    100% {
        right: -200px;
        transform: rotateZ(0);
    }
}

@media only screen and (max-width: 1150px) {
    .aboutSection {
        .rightSection {
            .featureSection {
                grid-template-columns: 1fr;

                .feature {
                    display: grid;
                }

                p {
                    font-size: 18px;
                }
            }
        }
    }
}

@media only screen and (max-width: 820px) {


    
    @keyframes foodAnimation {
        0% {
            bottom: -500px;
            transform: rotateZ(-45deg);
        }
        100% {
            bottom: -50px;
            transform: rotateZ(0);
        }
    }

    .aboutSection {
        grid-template-columns: 1fr;

        .leftSection {
            height: 550px;
            min-height: 550px;
            .backgroundBox {
                height: 550px;

                .food {
                    height: 350px;
                }
    
                .coffee {
                    height: 300px;
                }
            }
        }
        .rightSection {
            padding: 1rem;

            .featureSection {

                p {
                    font-size: 16px;
                }
            }
        }
    }
}
