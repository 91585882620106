@import '../../variable.scss';

.menu {
    display: grid;
    position: fixed;
    top: 0;
    height: 100vh;
    top: -100vh;
    left: 0;
    width: 100vw;
    background-color: $panel-background-colour;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    z-index: 1000;


    ul {
        list-style: none;
        display: grid;
        align-content: center;
        grid-row-gap: 10px;
        margin-top: 70px;
        padding: 0;
        li {

            a{
                text-decoration: none;
                color: black;

                &:hover{
                    cursor: pointer;
                    color: $appBackgroundColour;
                }
            }

            &:hover{
                cursor: pointer;
                color: $appBackgroundColour;
            }
        }
    }
}

.menuOpen {
    animation-name: menuOpen;
}

.menuClose {
    animation-name: menuClose;
}

@keyframes menuOpen {
    0% {
        top: -100vh;
    }
    100% {
        top: 0;
    }
}

@keyframes menuClose {
    0% {
        top: 0;
    }
    100% {
        top: -100vh;
    }
}
