@import '../../variable.scss';

.heroSection {
    display: grid;
    align-content: center;
    row-gap: $s8;
    padding: 1rem;


    .heroSectionMiddle {
        display: grid;
        font-size: 20px;
        justify-items: center;

        


        h1,
        span {
            animation-name: bottomUpAnimation;
            position: relative;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-delay: 0.5s;
            opacity: 0;
        }


        h1 {
            position: relative;



            .winner-status {
                font-family: $handFont;
                display: grid;
                align-items: center;
                height: 50px;
                position: absolute;
                left: -$s10;
                font-size: $s3 !important;

                row-gap: $s2;
                
                
     

                img {
                    height: $s8;

                    &:hover{
                        cursor: pointer;
                    }
                }

                span {
                    position: relative;
                    left: -$s10;
                    transform: rotateZ(10deg);

                    img{
                        height: $s4;
                    }

                    &:hover{
                        cursor: pointer;
                    }

                }
            }

            .winner-status:hover {
                cursor: pointer;
            }
        }

        span {
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;

        }
    }

    .heroButtonSection {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $s2;

        .bookATableButton,
        .menuButton {
            min-width: 150px;
            animation-name: bottomUpAnimation;
            position: relative;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-delay: 0.75s;
            opacity: 0;

            &:hover {
                cursor: pointer;
            }
        }

        .bookATableButton {
            background-color: $primary-colour;
            color: $appBackgroundColour;

            &:hover {
                background-color: $appBackgroundColour;
                color: $primary-colour;
            }
        }
    }



    .heroSectionBottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .bottomSection {
            display: grid;
            justify-items: center;
            flex: 1;

            &.downArrowSection {
                position: relative;
                animation-name: bottomUpAnimation;
                animation-duration: 1s;
                animation-fill-mode: forwards;
                opacity: 0;
            }

            .downArrow {
                height: 36px;
                width: 36px;
                animation-name: downScroll;
                animation-fill-mode: forwards;
                animation-duration: 1.5s;
                animation-delay: 3.5s;

                &:hover {
                    animation-name: downScroll;
                    cursor: pointer;
                }
            }
        }

        .socialMediaContainer {
            justify-content: flex-end;
            margin-right: 1rem;
            align-items: end;
        }
    }
}

@keyframes downScroll {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(10px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}


@keyframes bottomUpAnimation {
    0% {
        bottom: -50px;
        opacity: 0;
    }

    100% {
        bottom: 0;
        opacity: 1;
    }
}


@media only screen and (max-width: $largeScreen) {
    .heroSection {

        .heroSectionMiddle {

            h1 {
                .winner-status {
                    position: absolute;
                    left: $s10;

                    transform: rotateX(180deg);
                    top: -$s5;

                    img {
                        height: $s8;
                    }

                    span {
                        position: relative;
                        left: -$s10;
                        transform: rotateZ(10deg) rotateX(180deg);
                    }
                }

                .winner-status:hover {
                    cursor: pointer;
                }
            }
        }

    }
}

@media only screen and (max-width: 500px) {
    .heroSection {
        .heroSectionMiddle {
            svg {
                max-height: 150px;
                width: auto;
            }

            p {
                font-size: 18px;
            }
        }
    }
}