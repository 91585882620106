.footerSection {
  display: grid;

  grid-row-gap: 50px;

  .topSection {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 2rem 5rem;

    .logo {
      max-height: 150px;
    }
  }

  .contactSection {
    display: grid;
    align-items: center;
    grid-row-gap: 10px;

    img {
      height: 36px;
      width: 36px;
    }

    span {
      text-align: left;
    }

    &>div {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }
    }
  }

  .formSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 15px;

    .formHeader {
      grid-column: span 2;
    }

    .contactUsSubmitButton {
      height: 50px;
    }
  }

  .bottomSection {
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    background-color: rgb(235, 235, 235);
    background-color: #282c34;
    .bodyParagraph{
      color: white;

      a{
        color: rgb(255, 197, 110);
      }
    }
  }

  .mobileSocials {
    display: none;
  }
}

@media only screen and (max-width: 1500px) {
  .footerSection {
    .topSection {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      padding: 1rem 3rem;
      margin-top: 75px;
      text-align: left;
      grid-column-gap: 100px;
      grid-row-gap: 50px;

      .logo {
        display: none;
      }

      .operationalHoursSection {
        margin-top: 50px;
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .footerSection {
    .topSection {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      flex-wrap: wrap;
      padding: 2rem;

      .contactSection {
        margin-left: 30px;

        &>div {
          img {
            margin-right: 4px;
          }
        }
      }

      .mobileSocials {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .footerSection {
    .topSection {
      text-align: center;
    }

    .formSection {
      display: grid;
      grid-template-columns: unset;

      .formHeader {
        grid-column: unset;
      }
    }

    .bottomSection {
      display: grid;
      padding: 1rem;
      justify-content: center;
    }
  }
}