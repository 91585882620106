//typography
$billboard: 200px;
$header1:102px;
$header2:72px;
$header3:51px;
$header4:36px;
$header5:26px;
$header6:18px;

$headerFont:  'Quando', serif;
$bodyFont: 'Judson', serif;
$handFont: 'Hachi Maru Pop', cursive;



//breakpoints
$fourkscreen: 1800px;
$largeScreen:1200px;
$tabletScreen: 768px;
$mobileScreen: 375px;



//spacing
$s1: 8px;
$s2: 16px;
$s3: 24px;
$s4: 32px;
$s5: 40px;
$s6: 48px;
$s7: 56px;
$s8: 64px;
$s9: 72px;
$s10: 80px;


//custom spacing variables
$section-spacing: 256px;





//colour pallete
$primary-colour: #0A0A0A;
$panel-background-colour: #EBEBEB;
$primary-text-colour: #242424;
$secondary-text-colour: #0A0A0A;
$appBackgroundColour: white;