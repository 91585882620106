@import '../../variable.scss';

.gallerySection {
    min-height: 550px;
    background-color: rgb(235, 235, 235);
    display: grid;
    justify-content: center;
    padding: 2rem;
    position: relative;
    align-content: center;
    margin-bottom: 200px;

    .instagram-link {
        font-family: $handFont;
        display: grid;
        align-items: center;
        height: 50px;
        position: absolute;
        bottom: $s1;
        right: 40vw;
        font-size: $s3 !important;
        transform: rotateZ(10deg) rotateY(180deg);
        row-gap: $s2;
        
        


        img {
            height: $s8;

            &:hover{
                cursor: pointer;
            }
        }

        h6 {
            position: relative;
            left: -$s10;
            max-width: 200px;
            transform: rotateZ(10deg) rotateY(180deg);
            font-family: $handFont;
            margin: 0;

            img{
                height: $s4;
            }

            &:hover{
                cursor: pointer;
            }

        }
    }

    .instagram-link:hover {
        cursor: pointer;
    }

    .innerContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-width: 75vw;
        grid-column-gap: 10px;
        height: 50vh;
        width: 1000px;

        .galleryImage {
            animation-fill-mode: forwards;
            animation-duration: 1s;
            position: relative;
            opacity: 0;
        }

        .rightSection {
            display: grid;
            height: 50vh;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .leftSection {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
            align-content: center;
            height: 50vh;
            grid-row-gap: 10px;

            .bottomRow{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 10px;
            }
        }
    }
}

@keyframes gallery1 {
    0% {
        left: -100px;
        top: -100px;
        opacity: 0;
    }
    100% {
        left: 0;
        top: 0;
        opacity: 1;
    }
}

@keyframes gallery2 {
    0% {
        left: -100px;
        opacity: 0;
    }
    100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes gallery3 {
    0% {
        left: -100px;
        bottom: -100px;
        opacity: 0;
    }
    100% {
        left: 0;
        bottom: 0;
        opacity: 1;
    }
}

@keyframes gallery4 {
    0% {
        right: -100px;
        top: -100px;
        opacity: 0;
    }
    100% {
        right: 0;
        top: 0;
        opacity: 1;
    }
}

@keyframes followUs {
    0% {
        left: -100px;
    }
    100% {
        left: 0;
    }
}
@keyframes followUs2 {
    0% {
        left: -80px;
    }
    100% {
        left: 0;
    }
}

@media only screen and (max-width: 1350px) {
    .gallerySection {
        margin-bottom: 50px;
        padding: 1rem;
        min-height: 650px;
    }
}

@media only screen and (max-width: 720px) {
    .gallerySection {
        min-height: 550px;
        .followUs {
            font-size: 32px;
        }
        .followUs2 {
            font-size: 20px;
        }
        .gallery2 {
            grid-column: span 2;
        }
        .gallery3 {
            display: none;
        }
    }
}
