
@import './variable.scss';
@import './scss-class.scss';

.App {
  text-align: center;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  position: relative;
}

// html{
//   scroll-behavior: smooth;
// }
#gallerySection{
  min-height: 550px;
}

#aboutSection{
  min-height: 100vh;
}

#footerSection{
  min-height: 300px;
}


.moneyPlant{
  position: absolute;
  right: -125px;
  top: -$s2;
  transform: rotateZ(-12.5deg);
  max-width: 50vw;
  height: auto;
  z-index: 10;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  font-family: $headerFont;
}

.sectionHeader {
  font-size: 48px;
  margin-bottom: 10px;
}

.headerUnderline {
  border-top: 4px solid black;
  position: relative;
  left: 50px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: underlineAnimation;
}

.bodyParagraph {
  font-family: $bodyFont;
  color: rgb(99, 99, 99);
  color: #282c34;
}

.appSection {
  min-height: 100vh;
}

.customButton {
  font-family: $bodyFont;
  font-size: 20px;
  outline: none;
  background-color: transparent;
  border: 2px solid black;
  padding: 1rem;
  transition: 0.25s;
  color: black;
}

.customButton:hover {
  cursor: pointer;
  background-color: black;
  color: white;
}

.text-primary {
  color: black;
}

.textSecondary {
  color: rgb(99, 99, 99);
  color: #282c34;
}

.contactUsInput {
  border: 2px solid black;
  font-family: $bodyFont;
  height: 50px;
  font-size: 16px;
  color: black;
  min-width: 250px;
}

a{
  color:black;
  text-decoration: none;
}

@keyframes underlineAnimation {
  0% {
    left: 50px;
  }
  100% {
    left: 0;
  }
}
